import React from "react";
import TagArticleCard from "./tagarticle-card";

const TagArticlesGrid = ({ articles }) => {
  return (
    <div className="container mt-12 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
      {articles.map((article) => (
        <TagArticleCard article={article} />
      ))}
    </div>
  );
};

export default TagArticlesGrid;
