import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import TagArticlesGrid from "../components/tagarticles-grid";
import Seo from "../components/Seo";

const TagPage = ({ data }) => {
  const tagarticle = data.strapiTag.articles;
  const tag = data.strapiTag;

  const seo = {
    metaTitle: tag.seo_title || tag.name,
    metaDescription: tag.seo_description || tag.description,
  };
  return (
    <Layout>
      <Seo seo={seo} />
      <header>
        <StaticImage
          src="../assets/images/blog/banner-blog.png"
          alt={tag.name}
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      </header>
      <main className="mt-8">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-neutral-700">{tag.name}</h1>
          <h2 className="mt-4 text-2xl text-neutral-500">
            {tag.seo_description}
          </h2>
        </div>
        <div className="mt-6 mb-20 flex flex-wrap gap-5 text-current">
          <TagArticlesGrid articles={tagarticle} />
        </div>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiTag(slug: { eq: $slug }) {
      id
      slug
      name
      seo_description
      seo_title
      articles {
        id
        slug
        title
        description
        cover {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.77)
            }
          }
        }
      }
    }
  }
`;
export default TagPage;
